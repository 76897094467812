var Tegeldepot = Tegeldepot || {};

Tegeldepot.Accordion = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);
        this.urlChecker = this.urlChecker.bind(this);

        this.options = jQuery.extend({}, {
            titleSelector: '.accordion-title',
        }, options);

        this.hookEvents();
        this.urlChecker();
    }

    hookEvents() {
        jQuery('body').on('click', this.options.titleSelector, (event) => {
            event.preventDefault();
            const link = event.currentTarget;
            jQuery(link).toggleClass('active');
        });
    }

    urlChecker() {
        const urlParam = this.getUrlParam('id');
        jQuery('#' + urlParam).addClass('active');
    }

    getUrlParam(name) {
        let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return decodeURI(results[1]) || 0;
        }
    }

};

jQuery(function () {
    new Tegeldepot.Accordion();
});
